import {
  type FC,
} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logo from "../logo.svg";

// Props:
//    in: the current app
const MainNavbar: FC<{in: string}> = (props) => {
  return <Navbar bg="dark" variant="dark" expand="lg">
    <Container>
      <Navbar.Brand href="#home">
        <img
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
        杂物堆
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto" activeKey={ props.in }>
          <Nav.Link eventKey="recipe" href="/">家庭菜谱</Nav.Link>
          <Nav.Link eventKey="drawer" href="/drawer">抽屉</Nav.Link>
          <Nav.Link eventKey="hamburger" href="/h">汉堡游戏</Nav.Link>
          <Nav.Link eventKey="photo_wall" href="/photo_wall">照片墙</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
}

export {
  MainNavbar,
};