import { useState, type FC } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {
    type DrawerMetadata,
    drawerContentUrl,
    getDrawerMetadata,
} from "../fetch/drawerFetch";

import styles from '../apps/DrawerApp.module.css';

type DrawerContentProps = {
    type?: string;    // If null, the content is loading
    message?: string
    contentUrl?: string
    filename?: string
}

const DrawerContent: FC<DrawerContentProps> = (props) => {
    const type = props.type;    // Could be null
    const message = props.message;
    if (type == null) {
        return <>【翻箱倒柜中……】</>;
    }
    if (type === "empty") {
        return <>【这个抽屉空无一物】</>;
    }
    if (type === "text") {
        return (
            <div>
                【你翻到一张小纸条，上面写着：
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">点击复制到剪切板</Tooltip>}
                >
                    <a onClick={() => navigator.clipboard.writeText(message!)}>{message}</a>
                </OverlayTrigger>
                】
            </div>);
    }
    if (type === "image") {
        return (
            <div>
                【你找到一张照片，
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{props.filename}</Tooltip>}
                >
                    <a href={props.contentUrl + "?download=true"} download={props.filename}>点此下载</a>
                </OverlayTrigger>
                】
                <div><img className={styles.PreviewImage} src={props.contentUrl} /></div>
            </div>);
    }
    if (type === "file") {
        return (
            <div>
                【你找到一份秘密文件，
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{props.filename}</Tooltip>}
                >
                    <a href={props.contentUrl + "?download=true"} download={props.filename}>点此下载</a>
                </OverlayTrigger>
                】
            </div>);
    }
    return <>{`【${type}是不支持的内容类型】`}</>;
}

type DrawerProps = {
  name: string,
  opened: boolean,
  onChangeOpened: (opened: boolean) => void,
  handleClickUpdateButton: (arg0: any) => void,
}

const Drawer: FC<DrawerProps> = (props) => {
    const name = props.name;
    const opened = props.opened;
    const onChangeOpened = props.onChangeOpened;
    // State: drawer metadata
    const [metadata, setMetadata] = useState<DrawerMetadata | null>(null);

    return (
        <p className={styles.Drawer} key={name}>
            抽屉 {name}
            <br />
            {
                opened
                    ? (metadata == null
                        ? <DrawerContent />
                        : <DrawerContent
                            type={metadata.type}
                            message={metadata.message}
                            contentUrl={drawerContentUrl(name)}
                            filename={metadata.filename} />)
                    : <a onClick={() => {
                        // Clear possibily outdated drawer metadata.
                        setMetadata(null);
                        onChangeOpened(true);
                        getDrawerMetadata(name)
                            .then(res => {
                                // Update drawer metadata based on response
                                setMetadata(res.result.metadata);
                            })
                            .catch(err => {
                                console.log(err);
                                onChangeOpened(false);
                            });
                    }}>打开</a>
            }
            <div><a onClick={props.handleClickUpdateButton}>塞进新内容</a></div>
        </p>);
}

export {
    Drawer,
};