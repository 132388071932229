import {
  type FC,
  type PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  login,
} from "../fetch/loginFetch";

const Login: FC<PropsWithChildren<{}>> = (props) => {
  const [password, setPassword] = useState<string | null>();
  const [token, setToken] = useState<string | null>();

  const loadTokenFromStorage = () => {
    const tokenString = localStorage.getItem("token");
    if (tokenString !== null) {
      setToken(tokenString);
    }
  }

  const handleSubmit = async (e: any) => {
    if (password) {
      e.preventDefault();
      const response = await login(password);
      setToken(response.result!.token);
      localStorage.setItem("token", response.result!.token);
    }
  }

  useEffect(loadTokenFromStorage, [])

  if (!token) {
    return (
      <form onSubmit={handleSubmit}>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    );
  }

  return <>{props.children}</>;
}

export {
  Login,
};