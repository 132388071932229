import { type FC, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const kDisplayLinebreakClassName = "display-linebreak";

type DrawerUpdateModalProps = {
    show: boolean,
    // Callback when closing this modal.
    handleClose: () => void,
    // Callback when submitting change. Takes a message or a file.
    handleSubmitChange: (type: string, message: string | null, file: File | null) => void
}

// States:
//   newNoteContent: Text input value
const DrawerUpdateModal: FC<DrawerUpdateModalProps> = (props) => {
    const [inputType, setInputType] = useState("text");
    const [newNoteContent, setNewNoteContent] = useState("");
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    function resetAllInputs() {
        // Reset to text input
        setInputType("text");
        // Clear the input boxes
        setNewNoteContent("");
        setSelectedImage(null);
        setSelectedFile(null);
    }

    function hasSomethingToSubmit() {
        switch (inputType) {
            case "text":
                return newNoteContent !== "";
            case "image":
                return selectedImage !== null;
            case "file":
                return selectedFile !== null;
            default:
                return false;
        }
    }

    function onSubmitNewNote() {
        switch (inputType) {
            case "text":
                props.handleSubmitChange("text", newNoteContent, null);
                break;
            case "image":
                // let reader = new FileReader();
                // reader.addEventListener("load", function () {
                //     props.handleSubmitChange("image", reader.result);
                //     props.handleClose();
                //     resetAllInputs();
                // }, false);
                // reader.readAsDataURL(selectedImage);
                props.handleSubmitChange("image", null, selectedImage);
                break;
            case "file":
                props.handleSubmitChange("file", null, selectedFile);
                break;
            default:
                break;
        }
        props.handleClose();
        resetAllInputs();
    }

    function onClear() {
        props.handleSubmitChange("empty", null, null);
        props.handleClose();
        resetAllInputs();
    }

    // let noteBody;
    // if (props.noteContent === null) {
    //     noteBody = kLoadingNoteNotice;
    // } else {
    //     noteBody = (
    //         <ListGroup>
    //             {
    //                 props.noteContent.map(note => {
    //                     console.log(note);
    //                     let badge;
    //                     if (note.immutable) {
    //                         badge = <Badge bg="danger" pill>不可修改</Badge>;
    //                     }
    //                     return (
    //                         <ListGroup.Item key={ note.id }>
    //                             <Badge bg="success" pill>{ dateTimeStringToChineseDate(note.created_time) }</Badge>
    //                             { badge }
    //                             <p>{ note.text }</p>
    //                         </ListGroup.Item>
    //                     );
    //                 })
    //             }
    //         </ListGroup>
    //     );
    // }
    return (
        <Modal show={props.show} onHide={props.handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>放进抽屉</Modal.Title>
            </Modal.Header>
            <Modal.Body className={kDisplayLinebreakClassName}>
                <Tabs
                    id="drawer-update-modal-tab"
                    className="mb-3"
                    activeKey={inputType}
                    onSelect={(k) => setInputType(k ?? "text")} >
                    <Tab eventKey="text" title="小纸条">
                        <InputGroup>
                            <InputGroup.Text>小纸条</InputGroup.Text>
                            <FormControl
                                as="textarea"
                                aria-label="With textarea"
                                onChange={e => setNewNoteContent(e.target.value)}
                                value={newNoteContent} />
                        </InputGroup>
                    </Tab>
                    <Tab eventKey="image" title="图片">
                        <Form.Group controlId="uploadedImage" className="mb-3">
                            <Form.Label>上传图片</Form.Label>
                            <Form.Control type="file" onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                console.log(evt);
                                setSelectedImage(evt.target.files?.[0] ?? null);
                            }}
                            />
                        </Form.Group>
                    </Tab>
                    <Tab eventKey="file" title="文件">
                        <Form.Group controlId="uploadedFile" className="mb-3">
                            <Form.Label>上传文件</Form.Label>
                            <Form.Control type="file" onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                console.log(evt);
                                setSelectedFile(evt.target.files?.[0] ?? null);
                            }} />
                        </Form.Group>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onClear}>
                    清空抽屉
                </Button>
                <Button variant="secondary" onClick={props.handleClose}>
                    关闭
                </Button>
                <Button
                    variant="primary"
                    disabled={!hasSomethingToSubmit()}
                    onClick={onSubmitNewNote}>
                    确认
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export {
    DrawerUpdateModal,
};