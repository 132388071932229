type PlayerMove = "hamburger" | "shit" | "iEat" | "youEat" | "hamburgerDeluxe";
const kDefaultMove: PlayerMove = "hamburger";
const kAllPossibleMoves: ReadonlyArray<PlayerMove> = Object.freeze([
  "hamburger", "shit", "iEat", "youEat", "hamburgerDeluxe",
]);

export {
  type PlayerMove,
  kAllPossibleMoves,
  kDefaultMove,
};