// Libraries
import { type FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from "react-helmet";
import {
    type Photo,
    type RenderPhoto,
    type RenderPhotoProps,
    PhotoAlbum,
} from "react-photo-album";

import { MainNavbar } from "../components/MainNavBar";

// CSS & Assets
import photos, {type APhoto} from "../assets/photos";
import styles from "../apps/PhotoWallApp.module.css";

type PhotoUiProps = RenderPhotoProps<APhoto>;

const PhotoUi: FC<PhotoUiProps> = ({photo, layout, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    function handleClose() { setShowModal(false); }

    return <div
        className={styles.Photo}
        style={{
            border: "2px solid #eee",
            borderRadius: "4px",
            boxSizing: "content-box",
            alignItems: "center",
            width: style?.width,
            padding: `${layoutOptions.padding - 2}px`,
            paddingBottom: 0,
        }}
    >
        <img
            alt={alt}
            style={{ ...style, width: "100%", padding: 0 }}
            {...restImageProps}
            // The onClick handler must come at the end.
            onClick={() => {
                setShowModal(true);
            }}
            />
        <div
            style={{
                paddingTop: "8px",
                paddingBottom: "8px",
                overflow: "visible",
                whiteSpace: "nowrap",
                textAlign: "center",
            }}
        >
            {Math.round(layout.width) + " x " + Math.round(layout.height)}
        </div>
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>照片网址：{photo.src}</p>
                <p>日期：20XX年Y月Z日</p>
                <p>文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
};

const renderPhoto: RenderPhoto<APhoto> = (props) => (
    <PhotoUi {...props} />
);

type PhotoWallAppProps = {

};
const PhotoWallAppProps: FC<PhotoWallAppProps> = (props) => {
    const helmet = <Helmet><title>回忆相簿</title></Helmet>;
    const navbar = <MainNavbar in="photo_wall" />;
    return (
        <div className={styles.PhotoWallApp}>
            {helmet}
            {navbar}
            <header className={styles.AppHeader}>
                <img src="/hamburger.png" className={styles.AppLogo} alt="logo" />
            </header>
            <PhotoAlbum
                photos={photos}
                layout="rows"
                renderPhoto={renderPhoto}
            />
        </div>
    );
};

export default PhotoWallAppProps;
