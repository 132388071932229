import * as cb from "../../../common/cookbook_apis";

async function createNewRecipe(ops: Array<cb.PatchRecipeOperation>) {
  let request: cb.PatchRecipeRequestBody = {
    newPage: true,
    ops: ops,
  };
  const response = await fetch(
    "/recipes/NEW",
    {
      method: "PATCH",
      headers: {
        'Content-Type': "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(request),
    });
  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.error)
  }
  return body;
}

async function updateRecipe(recipeId: string, ops: Array<cb.PatchRecipeOperation>) {
  let request: cb.PatchRecipeRequestBody = {
    newPage: false,
    ops: ops,
  };
  const response = await fetch(
    `/recipe/${recipeId}`,
    {
      method: "PATCH",
      headers: {
        'Content-Type': "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(request),
    });
  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.error)
  }
  return body;
}

// Calls backend to get list of recipes
async function loadAllRecipes(): Promise<cb.GetAllRecipesResult> {
  const response = await fetch(
    "/recipes",
    {
      headers: { "accepts": "application/json" }
    });
  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.error);
  }
  return body;
};

async function getRecipeNote(recipeId: string) {
  const response = await fetch(
    `/recipe_note/${recipeId}`,
    {
      headers: { "accepts": "application/json" }
    });
  // console.log(await response.text());
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(`getRecipeNote failed with status code ${response.status}`);
  }
  return body;
};

async function appendRecipeNote(recipeId: string, newNoteContent: string) {
  const request: cb.PatchRecipeNoteRequestBody = {
    newContent: newNoteContent,
  };
  const response = await fetch(
    `/recipe_note/${recipeId}/note`,
    {
      method: "PATCH",
      headers: {
        'Content-Type': "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(request),
    })
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(`appendRecipeNote failed with status code ${response.status}`);
}
  return body;
}

export {
  appendRecipeNote,
  createNewRecipe,
  getRecipeNote,
  loadAllRecipes,
  updateRecipe,
};