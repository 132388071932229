import * as Colyseus from "colyseus.js";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import * as hg from "../../../common/HamburgerGame";
import { GamePanel } from "../components/GamePanel";
import { type GameRoom, JoinGamePanel } from "../components/JoinGamePanel";

// Assets
import hamburger from '../assets/hamburger.png';
import './HamburgerApp.css';

type ServerGameState = {
  s: hg.MyRoomState | null,
};

const client = new Colyseus.Client(process.env.REACT_APP_GAME_BACKEND_URL);
console.log(client);

// Join a game in @roomId with @userName.
// Provided callbacks:
//    @setStateCallback: Called when a room state update is received.
async function joinGame(
  userName: string, roomId: string, setStateCallback: (s: ServerGameState) => void): Promise<Colyseus.Room | null> {
  try {
    const joinOptions: hg.Api.MyRoomJoinOptions = {
      user: userName,
      testSinglePlayerGame: true,
    };
    const room = await client.joinById(roomId, joinOptions);
    console.log(room.sessionId, "joined", room.name);

    room.onStateChange((state) => {
      console.log(room.name, "has new state:", state);
      setStateCallback({ s: state as hg.MyRoomState });
    });

    room.onMessage("welcome", (message) => {
      console.log(room.sessionId, "received on", room.name, message);
    });

    room.onMessage(hg.Api.kProceedToNextRoundEvent, (message) => {

    })

    room.onError((code, message) => {
      console.log(room.sessionId, "couldn't join", room.name);
    });

    room.onLeave((code) => {
      console.log(room.sessionId, "left", room.name);
    });

    return room;
  } catch (e) {
    console.log("JOIN ERROR", e);
    return null;
  };
}

async function leaveGame(room: Colyseus.Room) {
  console.log("Leaving room");
  await room.leave();
}

function HamburgerApp() {
  const [allRooms, setAllRooms] = useState<Array<GameRoom>>([]);
  // The real-time game state from server
  const [serverGameState, setServerGameState] = useState<ServerGameState>({ s: null });
  const roomRef = useRef<Colyseus.Room | null>(null)

  // const a: HamburgerGame.PlayerMove = "hamburger";

  // Initialize the room list only once.
  useEffect(
    () => {
      client.getAvailableRooms("my_room").then(rooms => {
        setAllRooms(rooms.map((room) => {
          return {
            roomId: room.roomId,
            numClients: room.clients,
            maxClients: room.maxClients,
            displayName: room.metadata.displayName,
          };
        }));
      }).catch(e => {
        console.error(e);
      });
    }, [])

  const gameIsOngoing = (serverGameState.s?.ongoingGameState ?? null) !== null;

  return (
    <div className="App">
      <Helmet><title>家庭菜谱</title></Helmet>;
      <header className="App-header">
        <img src={hamburger} className="App-logo" alt="logo" />
      </header>
      <GamePanel
        gameIsOngoing={gameIsOngoing}
        serverGameState={serverGameState}
        mySessionId={roomRef.current?.sessionId ?? null}
        handleDecideNextMove={(move) => {
          console.log(roomRef);
          if (roomRef.current === null) {
            return false;
          }
          roomRef.current.send(
            hg.Api.kDecideNextMoveRequestName,
            { nextMove: move } as hg.Api.DecideNextMoveRequest);
          console.log("handleDecideNextMove returns true");
          return true;
        }} />
      <JoinGamePanel
        gameIsOngoing={gameIsOngoing}
        rooms={allRooms}
        handleJoinGame={(user, roomId) => {
          joinGame(user, roomId, setServerGameState).then(room => {
            roomRef.current = room;
          })
        }}
        handleLeaveGame={() => {
          if (roomRef.current !== null) {
            leaveGame(roomRef.current!);
            // Reset states
            roomRef.current = null;
            setServerGameState({ s: null });
          }
        }
        } />
    </div>
  );
}

export default HamburgerApp;
