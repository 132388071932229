// Libraries
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { Helmet } from "react-helmet";

// Local libraries
import * as cb from "../../../common/cookbook_apis";
import { createNewRecipe, loadAllRecipes } from "../fetch/recipeFetch";
import { isNullOrUndefined } from "../utils";
import { MainNavbar } from "../components/MainNavBar";
import { RecipeList } from "../components/RecipeList";

// CSS
import styles from "../apps/RecipeApp.module.css";

// Predefined labels
const kCuisineLabels = new Set(["西餐", "中餐", "粥", "汤", "蘸料"]);
const kProteinLabels = new Set(["牛肉", "鸡肉", "猪肉", "鱼肉", "虾", "蟹", "培根"]);
const kProteinNameHeuristics = ["牛", "鸡", "猪", "肉", "鱼", "鳕", "鲈", "骨"];

function extractAllLabels(recipes: Array<cb.RecipeInformation>) {
    let labelSet = new Set<string>();
    recipes.forEach(r => {
        r.labels.forEach((l: string) => {
            labelSet.add(l);
        });
    });
    return [...labelSet];
};

function groupLabelsHeuristic(labels: ReadonlyArray<string>) {
    let groupedLabels = {
        cuisine: ([] as Array<string>),
        protein: ([] as Array<string>),
        misc: ([] as Array<string>),
    }
    for (let l of labels) {
        if (kCuisineLabels.has(l)) {
            groupedLabels.cuisine.push(l);
        } else if (kProteinLabels.has(l)) {
            groupedLabels.protein.push(l);
        } else if (kProteinNameHeuristics.some(heuristic => l.includes(heuristic))) {
            groupedLabels.protein.push(l);
        } else {
            groupedLabels.misc.push(l);
        }
    }
    function compareInZhCN(a: string, b: string) {
        return a.localeCompare(b, "zh-Hans-CN");
    }
    groupedLabels.cuisine.sort(compareInZhCN);
    groupedLabels.protein.sort(compareInZhCN);
    groupedLabels.misc.sort(compareInZhCN);
    return groupedLabels;
};

type RecipeAppProps = {

}

type RecipeAppState = {
    labelFilters: Array<string>,
    recipes: Array<cb.RecipeInformation> | null,
};

class RecipeApp extends React.Component<RecipeAppProps, RecipeAppState> {
    constructor(props: RecipeAppProps) {
        super(props);
        this.state = {
            labelFilters: [],
            recipes: null,
        };
    };

    componentDidMount() {
        this.refreshRecipeList();
    };

    refreshRecipeList() {
        loadAllRecipes()
            .then(res => this.setState({ recipes: res.recipes }))
            .catch(err => console.log(err));
    }

    setLabelFilters(labelFilters: Array<string>) {
        this.setState({
            labelFilters: labelFilters,
        });
    };

    clearLabelFilters() {
        this.setLabelFilters([]);
    }

    handleAddNewRecipe(title: string, url: string | null, rank: string | null) {
        let ops: Array<cb.PatchRecipeOperation> = [
            { newTitle: title },
        ];
        if (!isNullOrUndefined(url)) {
            ops.push({ newUrl: url });
        }
        if (!isNullOrUndefined(rank)) {
            ops.push({ newRank: rank });
        }
        createNewRecipe(ops)
            .then(res => this.refreshRecipeList())
            .catch(err => console.log(err));
    }

    render() {
        // let props = this.props;
        let labelFilters = this.state.labelFilters;
        const helmet = <Helmet><title>家庭菜谱</title></Helmet>;
        const navbar = <MainNavbar in="recipe" />;
        const recipes = this.state.recipes;
        if (!recipes) {
            return (
                <div className={styles.RecipeApp}>
                    {helmet}
                    {navbar}
                    <Spinner animation="border" role="status" size="sm" className="px-1">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    载入数据中……
                </div>
            );
        }
        let labels = extractAllLabels(recipes);
        let groupedLabels = groupLabelsHeuristic(labels);
        // console.log(groupedLabels);
        return (
            <div className={styles.RecipeApp}>
                {helmet}
                {navbar}
                <header className={styles.AppHeader}>
                    <img src="/hamburger.png" className={styles.AppLogo} alt="logo" />
                </header>
                <div>
                    <ToggleButtonGroup
                        name="label-filters"
                        type="checkbox"
                        value={this.state.labelFilters}
                        onChange={(val) => { this.setLabelFilters(val) }}
                        style={
                            { display: "flex", flexWrap: "wrap", alignItems: "flex-start", }
                        }
                    >
                        {
                            groupedLabels.cuisine.map(l => (
                                <ToggleButton
                                    id={"toggle-button-" + l}
                                    key={l}
                                    variant="outline-primary"
                                    style={{ flex: "none" }}
                                    value={l}
                                >
                                    {l}
                                </ToggleButton>))
                        }
                        <div className={styles.break}></div>
                        {
                            groupedLabels.protein.map(l => (
                                <ToggleButton
                                    id={"toggle-button-" + l}
                                    key={l}
                                    variant="outline-primary"
                                    style={{ flex: "none" }}
                                    value={l}
                                >
                                    {l}
                                </ToggleButton>))
                        }
                        <div className={styles.break}></div>
                        {
                            groupedLabels.misc.map(l => (
                                <ToggleButton
                                    id={"toggle-button-" + l}
                                    key={l}
                                    variant="outline-primary"
                                    style={{ flex: "none" }}
                                    value={l}
                                >
                                    {l}
                                </ToggleButton>))
                        }
                        <div className={styles.break}></div>
                        <Button
                            variant="success"
                            style={{ flex: "none" }}
                            onClick={() => { this.clearLabelFilters(); }}
                        >
                            清空
                        </Button>
                        <Button
                            variant="warning"
                            style={{ flex: "none" }}
                            onClick={() => {
                                this.setState({ recipes: null });
                                this.refreshRecipeList();
                            }}
                        >
                            刷新菜谱
                        </Button>
                    </ToggleButtonGroup>

                    <Alert variant="warning">
                        当前筛选标签：{labelFilters.join("，") || "无"}
                    </Alert>
                </div>
                <RecipeList
                    recipes={recipes}
                    labelFilters={labelFilters}
                    handleAddNewRecipe={this.handleAddNewRecipe.bind(this)}
                />
            </div>
        );
    };

};

export default RecipeApp;
