type DrawerMetadata = {
  type: string,
  message: string,
  filename: string,
}

function drawerContentUrl(drawerName: string): string {
  return `/drawer/${drawerName}/file_content`;
}

function drawerMetadataUrl(drawerName: string): string {
  return `/drawer/${drawerName}/metadata`;
}

async function getDrawerMetadata(drawerName: string) {
  const response = await fetch(
      drawerMetadataUrl(drawerName),
      {
          headers: { "accepts":"application/json" }
      });
  const body = await response.json();
  if (response.status !== 200 || body.error != null) {
      throw Error(body.error) 
  }
  return body;
}

async function updateDrawerMetadata(drawerName: string, metadata: DrawerMetadata) {
  const response = await fetch(
    drawerMetadataUrl(drawerName),
      {
          method: "PATCH",
          headers: {
              'Content-Type': "application/json",
              "Accept": "application/json",
          },
          body: JSON.stringify({
              newMetadata: metadata,
          }),
      });
  if (response.status !== 200) {
      throw Error(`updateDrawerMetadata failed with status code ${response.status}`);
  }
  const result = await response.json();
  if (result.error != null) {
      throw Error("updateDrawerMetadata failed: " + result.error)
  }
  return;
}

async function updateDrawerContent(drawerName: string, metadata: DrawerMetadata, file: string) {
  await updateDrawerMetadata(drawerName, metadata);
  if (metadata.type === "file" || metadata.type === "image") {
      const data = new FormData();
      data.append('uploadedFile', file);
      const response = await fetch(
          drawerContentUrl(drawerName),
          {
              method: "PATCH",
              // Content-Type must NOT be set!
              body: data,
          });
      if (response.status !== 200) {
          throw Error(`updateDrawerContent failed with status code ${response.status}`);
      }
      let result = await response.json();
      if (result.error != null) {
          throw Error("updateDrawerContent failed: " + result.error)
      }
  }
  return;
}

export {
  type DrawerMetadata,
  drawerContentUrl,
  getDrawerMetadata,
  updateDrawerContent,
  updateDrawerMetadata,
};