import React, { type FC, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import * as cb from "../../../common/cookbook_apis";
import { dateTimeStringToChineseDate } from "../utils";
import { appendRecipeNote, getRecipeNote, updateRecipe } from "../fetch/recipeFetch";

import plusSvg from '../assets/plus.svg';
import styles from "../apps/RecipeApp.module.css";

const kDisplayLinebreakClassName = styles["display-linebreak"];
const kLoadingNoteNotice = (
    <div>
        <Spinner animation="border" role="status" size="sm" className="px-1">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <span>查询页面内容中……</span>
    </div>);

const DateBadge: FC<{ time: string | null | undefined }> = (props) => {
    return <Badge bg="success" pill>{props.time ? dateTimeStringToChineseDate(props.time) : "未知日期"}</Badge>;
}

type Note = {
    text: string,
    immutable?: boolean,
    id?: string,
    createdTime?: string | null,
};

type RatingInputGroupProps = {
    currentRating: string | null,
    onChange: (rating: string | null) => void,
};

const RatingInputGroup: FC<RatingInputGroupProps> = (props) => {
    const kUnrated = "UNRATED";
    return <InputGroup>
        <InputGroup.Text>评级</InputGroup.Text>
        <Form.Select
            aria-label="评级"
            onChange={e => props.onChange(e.target.value === kUnrated ? null : e.target.value)}
            value={props.currentRating ?? kUnrated}>
            <option value={kUnrated}>未评级</option>
            <option value="A">A级</option>
            <option value="B">B级</option>
        </Form.Select>
    </InputGroup>;
};

type EditRecipeNoteModalProps = {
    title: string,
    show: boolean,
    previousRating: string | null, // The rating before update
    noteContent: Array<Note>,
    handleClose: () => void,
    handleFinalizeNoteContent: (newNoteContent: string) => void,
    handleUpdateRecipe: (ops: Array<cb.PatchRecipeOperation>) => void,
};

const EditRecipeNoteModal: FC<EditRecipeNoteModalProps> = (props) => {
    const [newNoteContent, setNewNoteContent] = useState<string>("");
    const [ratingInput, setRatingInput] = useState<string | null>(props.previousRating);
    const [workingTab, setWorkingTab] = useState<string>("notes");

    function onSubmit() {
        if (workingTab === "notes") {
            props.handleFinalizeNoteContent(newNoteContent);
            setNewNoteContent("");  // Clear the input box
        } else {
            let ops: Array<cb.PatchRecipeOperation> = [];
            if (ratingInput !== props.previousRating) {
                ops.push({ newRank: ratingInput });
            }
            if (ops.length) {
                props.handleUpdateRecipe(ops);
            }
        }
    }

    function isPrimaryButtonDisabled() {
        if (workingTab === "notes") {
            return newNoteContent === "";
        } else {
            return ratingInput === props.previousRating;
        }
    }

    let noteBody;
    if (props.noteContent === null) {
        noteBody = kLoadingNoteNotice;
    } else {
        noteBody = (
            <ListGroup>
                {
                    props.noteContent.map(note => {
                        console.log(note);
                        let badge;
                        if (note.immutable) {
                            badge = <Badge bg="danger" pill>不可修改</Badge>;
                        }
                        return (
                            <ListGroup.Item key={note.id ?? "no-note"}>
                                <DateBadge time={note.createdTime} />
                                {badge}
                                <p>{note.text}</p>
                            </ListGroup.Item>
                        );
                    })
                }
            </ListGroup>
        );
    }
    return (
        <Modal show={props.show} onHide={props.handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{"编辑：" + props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Tabs
                    activeKey={workingTab}
                    onSelect={(k) => setWorkingTab(k ?? "notes")}
                    className="mb-3"
                >
                    <Tab eventKey="notes" title="小贴士" className={kDisplayLinebreakClassName}>
                        {
                            noteBody
                        }
                        <InputGroup>
                            <InputGroup.Text>新笔记</InputGroup.Text>
                            <FormControl
                                as="textarea"
                                aria-label="With textarea"
                                onChange={e => setNewNoteContent(e.target.value)}
                                value={newNoteContent} />
                        </InputGroup>
                    </Tab>
                    <Tab eventKey="properties" title="属性">
                        <RatingInputGroup
                            currentRating={ratingInput}
                            onChange={rating => setRatingInput(rating)} />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    关闭
                </Button>
                <Button
                    variant="primary"
                    disabled={isPrimaryButtonDisabled()}
                    onClick={onSubmit}>
                    {workingTab === "notes" ? "增加笔记" : "更新"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

type AddRecipeModalProps = {
    show: boolean,
    handleClose: () => void,
    handleSubmitChange: (title: string, url: string | null, rank: string | null) => void,
};
const AddRecipeModal: FC<AddRecipeModalProps> = (props) => {
    // Content of input title text box
    const [titleTextInput, setTitleTextInput] = useState<string>("");
    // Content of input URL text box
    const [urlInput, setUrlInput] = useState<string>("");
    // Content of input rating select
    const [ratingInput, setRatingInput] = useState<string | null>(null);

    function onSubmit() {
        props.handleSubmitChange(
            titleTextInput,
            urlInput ? urlInput : null,
            ratingInput);
        // Clear the input box
        setTitleTextInput("");
        setUrlInput("");
        setRatingInput(null);
    }
    // console.log(`ratingInput: ${JSON.stringify(ratingInput)}`);
    return (
        <Modal show={props.show} onHide={props.handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>添加新菜谱</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <InputGroup.Text>标题</InputGroup.Text>
                    <FormControl
                        aria-label="标题"
                        placeholder="未命名"
                        onChange={e => setTitleTextInput(e.target.value)}
                        value={titleTextInput} />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text>网址</InputGroup.Text>
                    <FormControl
                        aria-label="网址"
                        placeholder="http://xiachufang.com/recipe/Y"
                        onChange={e => setUrlInput(e.target.value)}
                        value={urlInput} />
                </InputGroup>
                <RatingInputGroup
                    currentRating={ratingInput}
                    onChange={rating => setRatingInput(rating)} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    关闭
                </Button>
                <Button
                    variant="primary"
                    disabled={titleTextInput === ""}
                    onClick={onSubmit}>
                    创建菜谱
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

type NewRecipeButtonProps = {
    handleAddNewRecipe: (title: string, url: string | null, rank: string | null) => void;
};
type NewRecipeButtonState = {
    showRecipeModal: boolean,
};
class NewRecipeButton extends React.Component<NewRecipeButtonProps, NewRecipeButtonState> {
    constructor(props: NewRecipeButtonProps) {
        super(props);
        this.state = {
            showRecipeModal: false,
        }
    };

    handleCloseRecipeModal() {
        this.setState({ showRecipeModal: false });
    }

    handleOpenRecipeModal() {
        this.setState({ showRecipeModal: true });
    }

    render() {
        return (
            <>
                <Card
                    className="shadow m-2"
                    border="dark"
                    style={{ width: "14rem" }}
                >
                    <Card.Img
                        src={plusSvg}
                        className={styles["new-recipe-button"]}
                        onClick={this.handleOpenRecipeModal.bind(this)} />
                    <Card.Body>
                        <Card.Text>
                            添加菜谱
                        </Card.Text>
                    </Card.Body>

                </Card>
                <AddRecipeModal
                    show={this.state.showRecipeModal}
                    handleClose={this.handleCloseRecipeModal.bind(this)}
                    handleSubmitChange={
                        (title, url, rank) => {
                            this.props.handleAddNewRecipe(title, url, rank);
                            this.handleCloseRecipeModal();
                        }
                    }
                />
            </>);
    }
}

type RecipeProps = {
    pageId: string,
    dateCreated: string,    // Serialized date object
    title: string,
    rating: string | null,
    labels: Array<string>,
    highlightLabels: Array<string>,
    recipeUrl: string | null,
};

type RecipeState = {
    // The content of notes. Null if it is not loaded
    noteContent: Array<Note> | null,
    // Whether to show recipe modal or not.
    showRecipeModal: boolean,
}

// One single block that shows the summary of a recipe
class Recipe extends React.Component<RecipeProps, RecipeState> {
    constructor(props: RecipeProps) {
        super(props);
        this.state = {
            noteContent: null,
            showRecipeModal: false,
        };
    };

    retrieveNoteContent(recipeId: string) {
        getRecipeNote(recipeId)
            .then((res) => {
                if (res.error !== null) {
                    console.log(res.error);
                    this.setState({
                        noteContent: [
                            {
                                text: `错误：${res.error}`,
                            }
                        ],
                    });
                } else if (!res.result[0]) {
                    this.setState({
                        noteContent: [
                            {
                                text: "暂无笔记",
                            }
                        ],
                    });
                } else {
                    this.setState({
                        noteContent: res.result
                    });
                }
            })
            .catch(err => console.log(err));
    };

    handleCloseRecipeNoteModal() {
        this.setState({ showRecipeModal: false });
    };

    handleShowRecipeNoteModal() {
        this.setState({ showRecipeModal: true });
    };

    clearCachedNoteContent() {
        this.setState({ noteContent: null });
    };

    handleAppendNote(recipeId: string, newNoteContent: string) {
        appendRecipeNote(recipeId, newNoteContent)
            .then(result => {
                if (result.error !== null) {
                    throw Error("handleAppendNote failed: " + result.error);
                }
                this.clearCachedNoteContent();
                this.retrieveNoteContent(recipeId);
            })
    };

    handleUpdateRecipe(recipeId: string, ops: Array<cb.PatchRecipeOperation>) {
        if (ops.length) {
            updateRecipe(recipeId, ops)
                .then(result => {
                    if (result.error !== null) {
                        throw Error("updateRecipe failed: " + result.error);
                    }
                    this.clearCachedNoteContent();
                    this.handleCloseRecipeNoteModal();
                    // FIXME: 刷新所有菜谱——需要插入一个事件？
                });
        }
    };

    render() {
        let props = this.props;
        let urlButton;
        if (props.recipeUrl) {
            urlButton = (
                <Button
                    variant="primary"
                    href={props.recipeUrl ?? "#"}>
                    查看菜谱
                </Button>
            );
        } else {
            urlButton = (
                <Button
                    variant="secondary">
                    暂无菜谱
                </Button>
            );
        }

        let notePopoverBody = kLoadingNoteNotice;
        if (this.state.noteContent !== null) {
            notePopoverBody = (
                <ListGroup variant="flush">
                    {
                        this.state.noteContent.map(note => {
                            return (
                                <ListGroup.Item key={note.id}>
                                    <DateBadge time={note.createdTime} />
                                    <p>{note.text}</p>
                                </ListGroup.Item>
                            );
                        })
                    }
                </ListGroup>
            );
        }

        // Show notes
        const popover = (
            <Popover
                id="popover-basic"
            >
                <Popover.Header as="h3">小贴士</Popover.Header>
                <Popover.Body
                    className={kDisplayLinebreakClassName}
                >
                    {
                        notePopoverBody
                    }
                </Popover.Body>
            </Popover>
        );

        // trigger="click" 
        const overlayTrigger = (
            <OverlayTrigger
                placement="top"
                overlay={popover}
                onToggle={
                    (show) => {
                        if (show && this.state.noteContent === null) {
                            this.retrieveNoteContent(props.pageId);
                        }
                    }
                }
            >
                <Button
                    variant="primary"
                    onClick={this.handleShowRecipeNoteModal.bind(this)}
                >
                    小贴士
                </Button>
            </OverlayTrigger>
        );

        const kLabelClass = "px-1";

        return (<>
            <Card
                className="shadow m-2"
                border="dark"
                style={{ width: "14rem" }}
                onClick={this.handleShowRecipeNoteModal.bind(this)}
            >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>
                        {new Date(props.dateCreated).toLocaleDateString("zh-CN", { dateStyle: "long" })}
                    </Card.Text>
                    <Card.Text>
                        评级：
                        <Badge
                            bg={props.rating === null ? "secondary" : (props.rating === "A" ? "warning" : "success")}
                            pill>
                            {props.rating ?? "暂无"}
                        </Badge>
                    </Card.Text>
                    {
                        props.labels.map(l => {
                            if (props.highlightLabels.includes(l)) {
                                return <Card.Link key={l} className={kLabelClass}>{l}</Card.Link>;
                            } else {
                                return <span className={kLabelClass}>{l}</span>;
                            }
                        })
                    }
                </Card.Body>
                <Card.Body>
                    {urlButton}
                    {overlayTrigger}
                </Card.Body>
            </Card>
            <EditRecipeNoteModal
                title={props.title}
                show={this.state.showRecipeModal}
                noteContent={this.state.noteContent ?? []}
                previousRating={props.rating ?? ""}
                handleClose={this.handleCloseRecipeNoteModal.bind(this)}
                handleFinalizeNoteContent={
                    (newNote) => {
                        this.handleAppendNote(props.pageId, newNote);
                    }
                }
                handleUpdateRecipe={
                    (ops) => {
                        this.handleUpdateRecipe(props.pageId, ops);
                    }
                }
            />
        </>
        );
    };
};  // class Recipe

export {
    NewRecipeButton,
    Recipe,
};
