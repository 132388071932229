import * as l from "../../../common/login_apis";

async function login(credential: string) : Promise<l.LoginResponse> {
  let request: l.LoginRequest = {
    credential: credential,
  };
  const response = await fetch(
    "/login",
    {
      method: "PATCH",
      headers: {
        'Content-Type': "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify(request),
    });
  const body : l.LoginResponse = await response.json();
  if (response.status !== 200) {
    throw Error(body.error!)
  }
  return body;
}

export {
  login,
};