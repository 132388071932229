import {
  type PlayerMove,
  kAllPossibleMoves,
  kDefaultMove,
} from "./PlayerMove";
import {
  type GameState, type MyRoomState,
} from "./schema/MyRoomState";

const kGameReadyDuration = 5;
const kRoundDuration = 5;

const kNumHamburgerForShit = 2;
const kNumHamburgerForHamburgerDeluxe = 3;

interface IPlayerState {
  hamburgerNumber: number,
  lastMove: PlayerMove | null,
  hasUsedHamburgerDeluxe: boolean,
};

function isMoveLegal(move: PlayerMove, playerState: Readonly<IPlayerState>): boolean {
  switch (move) {
    case "hamburger":
      return true;
    case "shit":
      return playerState.hamburgerNumber >= kNumHamburgerForShit;
    case "iEat":
      return playerState.lastMove === null || playerState.lastMove !== "iEat";
    case "youEat":
      return playerState.lastMove === null || playerState.lastMove !== "youEat";
    case "hamburgerDeluxe":
      return playerState.hamburgerNumber >= kNumHamburgerForHamburgerDeluxe && !playerState.hasUsedHamburgerDeluxe;
    default:
      return false;
  }
}

/*
 * Make a @move. Update @playerState by consuming necessary resources, recording last moves, etc.
 * If the @move is not legal, returns false.
 */
function updatePlayerStateByMove(move: PlayerMove, playerState: IPlayerState): boolean {
  if (!isMoveLegal(move, playerState)) return false;
  switch (move) {
    case "hamburger":
      break;
    case "shit":
      playerState.hamburgerNumber -= kNumHamburgerForShit;
      break;
    case "iEat":
      break;
    case "youEat":
      break;
    case "hamburgerDeluxe":
      playerState.hamburgerNumber -= kNumHamburgerForHamburgerDeluxe;
      playerState.hasUsedHamburgerDeluxe = true;
      break;
    default:
      return false;
  }
  playerState.lastMove = move;
  return true;
}

type ClassifiedOneRoundCase = {
  type: "tie",
} | {
  type: "eatHamburger",
  hamburgerEater: string,
} | {
  type: "eatShit",
  surviver: string,
} | {
  type: "eatHamburgerDeluxe",
  hamburgerDeluxeEater: string,
}

function classifyOneRoundCase(moves: ReadonlyMap<string, PlayerMove>) : ClassifiedOneRoundCase {
  if (moves.size !== 2) {
    throw new Error("Number of players must be 2.");
  }
  let [a, b] = moves.keys();
  let [aMove, bMove] = [moves.get(a)!, moves.get(b)!];
  if (aMove === bMove) {
    // Same moves will always tie.
    return { type: "tie" };
  }
  if (aMove === "hamburger" || bMove === "hamburger") {
    if (bMove === "hamburger") {
      [a, b] = [b, a];
      [aMove, bMove] = [bMove, aMove];
    }
    switch (bMove) {
      case "iEat":
        return { type: "eatHamburger", hamburgerEater: b };
      case "youEat":
        return { type: "eatHamburger", hamburgerEater: a };
      default:
        return { type: "tie" };
    }
  } else if (aMove === "shit" || bMove === "shit") {
    if (bMove === "shit") {
      [a, b] = [b, a];
      [aMove, bMove] = [bMove, aMove];
    }
    switch (bMove) {
      case "iEat":
        return { type: "eatShit", surviver: a };
      case "youEat":
        return { type: "eatShit", surviver: b };
      default:
        return { type: "tie" };
    }
  } else if (aMove === "hamburgerDeluxe" || bMove === "hamburgerDeluxe") {
    if (bMove === "hamburgerDeluxe") {
      [a, b] = [b, a];
      [aMove, bMove] = [bMove, aMove];
    }
    switch (bMove) {
      case "iEat":
        return { type: "eatHamburgerDeluxe", hamburgerDeluxeEater: b };
      case "youEat":
        return { type: "eatHamburgerDeluxe", hamburgerDeluxeEater: a };
      default:
        return { type: "tie" };
    }
  }
  return { type: "tie" };
}

namespace Api {
  export type MyRoomJoinOptions = {
    user: string,
    // If true, add a pseudo opponent to play a dummy game.
    testSinglePlayerGame?: boolean,
  }

  // Client -> server
  export const kDecideNextMoveRequestName = "decideNextMoveRequest";
  export type DecideNextMoveRequest = {
    nextMove: PlayerMove;
  }

  // Server -> client
  export const kProceedToNextRoundEvent = "proceedToNextRoundEvent";
};


export {
  type ClassifiedOneRoundCase,
  type GameState,
  type IPlayerState,
  type MyRoomState,
  type PlayerMove,
  Api,
  // Functions
  classifyOneRoundCase,
  isMoveLegal,
  updatePlayerStateByMove,
  // Constants
  kAllPossibleMoves,
  kDefaultMove,
  kGameReadyDuration,
  kRoundDuration,
};