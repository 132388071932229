// Libraries
import React from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from "react-helmet";

import {
    updateDrawerContent,
} from "../fetch/drawerFetch";
import { MainNavbar } from "../components/MainNavBar";
import { Drawer } from "../components/Drawer";
import { DrawerUpdateModal } from "../components/DrawerUpdateModal";

import styles from './DrawerApp.module.css';

const kDrawerNames = Object.freeze(["top", "middle", "bottom"]);

class DrawerApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawers: kDrawerNames.map(value => {
                return { name: value, opened: false };
            }),
            currentDrawerToEdit: null,
        };
    };

    updateDrawerOpen(drawerName, opened) {
        this.setState((prevState, props) => { 
            const newDrawers = prevState.drawers.map(d => {
                return {
                    name: d.name,
                    opened: d.name === drawerName ? opened : d.opened,
                }
            });
            return {
                drawers: newDrawers,
            };
        })
    }

    handleOpenDrawerUpdateModal(drawerName) {
        this.setState({ currentDrawerToEdit: drawerName });
    }

    handleCloseDrawerUpdateModal() {
        this.setState({ currentDrawerToEdit: null });
    }

    handleChangeDrawerContent(drawerName, type, message, file) {
        const metadata = { type: type };
        if (type === "text") {
            metadata.message = message;
        }
        if (type === "image" || type === "file") {
            metadata.filename = file.name;
            metadata.mimeType = file.type;
        }

        updateDrawerContent(drawerName, metadata, file)
            .then(_ => {
                this.updateDrawerOpen(drawerName, false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        if (this.state.currentDrawerToEdit !== null) {
            console.log(`Editing drawer ${this.state.currentDrawerToEdit}.`);
        }
        return (
            <div className={styles.DrawerApp}>
                <Helmet>
                    <title>神秘抽屉</title>
                </Helmet>
                <MainNavbar in="drawer" />
                <header className={styles.AppHeader}>
                    <img src="/hamburger.png" className={styles.AppLogo} alt="logo" />
                </header>
                {
                    this.state.drawers.map(
                        d =>
                            <Drawer
                                key={d.name}
                                name={d.name}
                                opened={d.opened}
                                handleClickUpdateButton={(evt) => { this.handleOpenDrawerUpdateModal(d.name) }}
                                onChangeOpened={(opened) => this.updateDrawerOpen(d.name, opened)} />)
                }
                <Button
                    onClick={() => {
                        kDrawerNames.forEach(
                            drawer => this.updateDrawerOpen(drawer, false));
                    }}>
                    关上所有抽屉
                </Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        kDrawerNames.forEach(
                            drawer => this.handleChangeDrawerContent(drawer, "empty"));
                    }}>
                    清空所有抽屉
                </Button>
                <DrawerUpdateModal
                    show={this.state.currentDrawerToEdit !== null}
                    handleClose={this.handleCloseDrawerUpdateModal.bind(this)}
                    handleSubmitChange={(type, message, file) => {
                        this.handleChangeDrawerContent(this.state.currentDrawerToEdit, type, message, file);
                    }} />
            </div>);
    };

};

export default DrawerApp;
