import { type FC } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import * as cb from "../../../common/cookbook_apis";
import { NewRecipeButton, Recipe } from './Recipe';

type RecipeListProps = {
    recipes: Array<cb.RecipeInformation>,
    labelFilters: Array<string> | null,
    handleAddNewRecipe: (title: string, url: string | null, rank: string | null) => void
};

const RecipeList: FC<RecipeListProps> = (props) => {
    let labelFilters = props.labelFilters || [];
    return (
        <Container fluid>
            <Row>
                {
                    [
                        <Col
                            key="NEWPAGE"
                            className="flex-grow-0"
                        >
                            <NewRecipeButton handleAddNewRecipe={props.handleAddNewRecipe} />
                        </Col>,
                    ].concat(
                        props.recipes.map(r => {
                            if (!labelFilters.length || labelFilters.every(l => r.labels.includes(l))) {
                                return (
                                    <Col
                                        key={r.pageId}
                                        className="flex-grow-0"
                                    >
                                        <Recipe
                                            pageId={r.pageId}
                                            dateCreated={r.dateCreated}
                                            title={r.title}
                                            rating={r.rating}
                                            labels={r.labels}
                                            highlightLabels={labelFilters}
                                            recipeUrl={r.recipeUrl}
                                        />
                                    </Col>
                                );
                            }
                            return <></>;
                        }))
                }
            </Row>
        </Container>
    );
}

export {
    RecipeList,
};