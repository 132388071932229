import {
  useState,
  type FC,
} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

type GameRoom = {
  roomId: string,
  numClients: number,
  maxClients: number,
  displayName: string,
}

type JoinGamePanelProps = {
  gameIsOngoing: boolean,
  rooms: ReadonlyArray<GameRoom>,
  handleJoinGame: (user: string, roomId: string, testSinglePlayerGame: boolean) => void;
  handleLeaveGame: () => void;
};

const JoinGamePanel: FC<JoinGamePanelProps> = (props) => {
  const [userName, setUserName] = useState<string>("AAA");
  const [testSinglePlayerGame, setTestSinglePlayerGame] = useState<boolean>(false);

  let inner = null;

  if (props.gameIsOngoing) {
    inner = <Button variant="danger" onClick={props.handleLeaveGame}>离开游戏</Button>
  } else {
    inner = <>
      <ul>
        {
          props.rooms.map(room =>
            <li key={room.roomId}>
              {room.displayName}&nbsp;&nbsp;
              {room.numClients}/{room.maxClients}&nbsp;&nbsp;
              ID: <code>{room.roomId}</code>
            </li>)
        }
      </ul>
      <InputGroup>
        <InputGroup.Text>用户名</InputGroup.Text>
        <FormControl
          as="textarea"
          aria-label="With textarea"
          onChange={e => setUserName(e.target.value)}
          value={userName} />
      </InputGroup>

      <Form.Check
        inline
        type="switch"
        id="switch-test-single-player-game"
        label="对战电脑"
        onChange={e => setTestSinglePlayerGame(e.target.checked)}
      />
      {testSinglePlayerGame}
      <Button
        variant="primary"
        disabled={userName === "" || props.rooms.length === 0}
        onClick={() => props.handleJoinGame(userName, props.rooms[0].roomId, testSinglePlayerGame)}>
        加入游戏
      </Button>
    </>;
  }
  return <div className="bg-dark text-light" style={{padding: "1em 20vw 1em 20vw"}}> {inner} </div>;
};

export {
  type GameRoom,
  JoinGamePanel,
};