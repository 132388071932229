import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';  // This needs to go before `import App`
import './index.css';
import { Login } from "./components/Login";
import DrawerApp from './apps/DrawerApp';
import HamburgerApp from './apps/HamburgerApp';
import PhotoWallApp from './apps/PhotoWallApp';
import RecipeApp from './apps/RecipeApp';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Login>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RecipeApp />} />
          <Route path="/drawer" element={<DrawerApp />} />
          <Route path="/h" element={<HamburgerApp />} />
          <Route path="/photo_wall" element={<PhotoWallApp />} />
        </Routes>
      </BrowserRouter>
    </Login>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
