import {
  useEffect,
  useState,
  type FC,
} from "react";
// @ts-ignore
import { AwesomeButton } from "react-awesome-button";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import * as hg from "../../../common/HamburgerGame";

import "./GamePanel.css";
import hamburger from '../assets/hamburger.png';

// Default style of awesome-button: import "react-awesome-button/dist/styles.css";
// But here are some good themes: amber, red, eric, rickiest
import "react-awesome-button/dist/themes/theme-red.css";


const kHamburgerDisplayLimit = 10;

const kAllMoves = Object.freeze([
  "iEat",
  "hamburger",
  "youEat",
  "shit",
  "hamburgerDeluxe",
]);

const kMovePresentation = Object.freeze(new Map([
  ["iEat", ["👈我吃", "secondary"]],
  ["hamburger", ["汉🍔堡", "primary"]],
  ["youEat", ["你吃👉", "secondary"]],
  ["shit", ["💩💩", "disabled"]],
  ["hamburgerDeluxe", ["巨🍔无🍔霸", "warning"]],
]));

type MoveButtonProps = {
  move: string,
  disabled: boolean,
  highlight: boolean,
  // Returns if the @move is legal or not.
  handleDecideNextMove: (move: string) => boolean,
};

const MoveButton: FC<MoveButtonProps> = (props) => {
  if (!kMovePresentation.has(props.move)) return <>Not a move!!!</>;
  const [text, variant] = kMovePresentation.get(props.move)!;
  // return <>
  //   <Button
  //     disabled={props.disabled}
  //     variant={props.highlight ? variant : `outline-${variant}`}
  //     size="lg"
  //     onClick={() => props.handleDecideNextMove(props.move)}>
  //     {text}
  //   </Button>
  // </>;
  // @ts-ignore
  let style: any = {
    margin: "4px",
    fontSize: "20px",
    "--button-medium-height": "3em",
    "--button-large-height": "4em",
  };
  let type = variant;
  let size = "medium";
  if (props.move === "hamburgerDeluxe") {
    type = "primary";
    style = {
      ...style,
      '--button-primary-color': "#ffc60a",
      // Darken by 15%
      '--button-primary-color-dark': "#e1ac00",
      // Lighten by 80%
      '--button-primary-color-light': "white",
      // Darken by 5%
      '--button-primary-color-hover': "#fbc100",
      // Darken by 10%
      '--button-primary-color-active': "#eeb700",
    }
    size = "large";
  } else if (props.move === "shit") {
    type = "primary";
    style = {
      ...style,
      '--button-primary-color': "#710193",  // Violet
      // Darken by 15%
      '--button-primary-color-dark': "#60007c",
      // Lighten by 80%
      '--button-primary-color-light': "#edb7fe",
      // Darken by 5%
      '--button-primary-color-hover': "#6b008b",
      // Darken by 10%
      '--button-primary-color-active': "#650084",
    }
  }
  let className = "MoveButton";
  if (props.highlight) {
    className += " MoveButton-highlight";
  }
  return <div className={className}>
    <AwesomeButton
      type={type}
      size={size}
      disabled={props.disabled}
      // ripple
      onPress={() => props.handleDecideNextMove(props.move)}
      style={style}
    >
      {text}
    </AwesomeButton>
  </div>;
};

type StatusChipProps = {
  gameIsOngoing: true,
  roundNumber: number,
  countDown: number,
  winner: "me" | "opponent" | null,
} | {
  gameIsOngoing: false,
};

const StatusChip: FC<StatusChipProps> = (props) => {
  if (!props.gameIsOngoing) {
    return <div>请选择一个房间加入</div>;
  }
  return <div>
    {
      `第${props.roundNumber}回合 ` +
      (props.winner === null
        ? `剩余${Math.ceil(props.countDown)}秒`
        : (props.winner === "me" ? "你赢了！" : "你输了……"))
    }
  </div>;
}

type HamburgerAreaProps = { name: string, number: number, background: string };

const HamburgerArea: FC<HamburgerAreaProps> = (props) => {
  const kHamburgerImg = <img src={hamburger} style={{ margin: "2px", width: "2.5em", }}></img>;
  return <>
    <p>{props.name}的汉堡</p>
    <div style={{
      margin: "4px",
      padding: "4px",
      fontSize: "24px",
      minHeight: "2.5em",
      background: props.background,
    }}
    >

      {
        props.number > kHamburgerDisplayLimit
          ? <>{kHamburgerImg} ×{props.number}</>
          : Array(props.number).fill(kHamburgerImg)
      }

    </div>
  </>;
};

function getMyPlayerState(mySessionId: string, s: Readonly<hg.GameState>) {
  return s.players.get(mySessionId)!;
}

function getOpponentPlayerState(mySessionId: string, s: Readonly<hg.GameState>) {
  if (s.players.size !== 2 || !s.players.has(mySessionId)) {
    throw new Error("Invalid opponent state");
  }
  for (const [sessionId, state] of s.players) {
    if (sessionId !== mySessionId) {
      return state;
    }
  }
  throw new Error("Invalid opponent state");
}

type MoveHistory = {
  roundNumber: number,
  myMove: hg.PlayerMove,
  opponentMove: hg.PlayerMove,
}

type GamePanelProps = {
  gameIsOngoing: boolean,
  serverGameState: {
    s: hg.MyRoomState | null,
  },
  mySessionId: string | null,
  // Returns if the @move is legal or not.
  handleDecideNextMove: (move: string) => boolean,
};

// TODO: This code is very very messy. Need refactoring to better define interface / states.
const GamePanel: FC<GamePanelProps> = (props) => {
  const [userName, setUserName] = useState<string>("");
  const [decidedMove, setDecidedMove] = useState<string>("");
  const [lastRound, setLastRound] = useState<number | null>(null);
  const [moveHistory, setMoveHistory] = useState<Array<MoveHistory>>([]);

  useEffect(
    () => {
      if (props.gameIsOngoing) {
        const currentRound = props.serverGameState.s?.ongoingGameState?.roundNumber!;
        if (lastRound !== currentRound) {
          const myState = getMyPlayerState(props.mySessionId!, s);
          const opponentState = getOpponentPlayerState(props.mySessionId!, s);
          if (lastRound !== null) {
            // Record last round's move
            setMoveHistory([
              { roundNumber: lastRound, myMove: myState.lastMove!, opponentMove: opponentState.lastMove! },
            ].concat(moveHistory));
          }
          setLastRound(currentRound);
          setDecidedMove("");
        }
      } else {
        setLastRound(null);
      }
    }, [props.serverGameState]);

  function handleDecideNextMoveWrapper(move: string) {
    const result = props.handleDecideNextMove(move);
    if (result) {
      setDecidedMove(move);
    }
    return result;
  }

  if (!props.gameIsOngoing) {
    return <></>;
  }

  const s = props.serverGameState.s?.ongoingGameState!;
  const myState = getMyPlayerState(props.mySessionId!, s);
  const opponentState = getOpponentPlayerState(props.mySessionId!, s);
  let myHamburgerNumber = myState.hamburgerNumber;

  const countDown = s.lastRoundTime + hg.kRoundDuration - props.serverGameState.s?.t!;
  return <div className="bg-dark text-light" style={{
    fontSize: "20px",
    padding: "2em 5vw 2em 5vw"
  }}>
    {
      props.gameIsOngoing
        ? <StatusChip gameIsOngoing={true}
          countDown={countDown}
          roundNumber={s.roundNumber}
          winner={s.winner ? (s.winner === props.mySessionId ? "me" : "opponent") : null}
        />
        : <StatusChip gameIsOngoing={false} />
    }

    <p>
      {
        decidedMove === ""
          ? "请出招"
          : <>
            你的下一招：<div style={{
              margin: "4px",
              padding: "4px",
              fontSize: "24px",
              background: "rgb(255, 255, 255, 0.1)",
            }}>
              {kMovePresentation.get(decidedMove)![0]}
            </div>
          </>
      }
    </p>

    <Container fluid={true}>
      <Row>
        <Col sm={3} lg={2}>
          <HamburgerArea
            name={myState.name}
            number={myHamburgerNumber}
            background="rgb(7, 1, 255, 0.1)" />
        </Col>
        <Col>
          <Container fluid={true}>
            <Row className="justify-content-sm-center">
              <Col>
                <MoveButton
                  disabled={!hg.isMoveLegal("hamburgerDeluxe", myState)}
                  move="hamburgerDeluxe"
                  highlight={decidedMove === "hamburgerDeluxe"}
                  handleDecideNextMove={handleDecideNextMoveWrapper} />
              </Col>
            </Row>
            <Row>
              <Col>
                <MoveButton
                  disabled={!hg.isMoveLegal("iEat", myState)}
                  move="iEat"
                  highlight={decidedMove === "iEat"}
                  handleDecideNextMove={handleDecideNextMoveWrapper} />
              </Col>
              <Col>
                <MoveButton
                  disabled={!hg.isMoveLegal("hamburger", myState)}
                  move="hamburger"
                  highlight={decidedMove === "hamburger"}
                  handleDecideNextMove={handleDecideNextMoveWrapper} />
              </Col>
              <Col>
                <MoveButton
                  disabled={!hg.isMoveLegal("youEat", myState)}
                  move="youEat"
                  highlight={decidedMove === "youEat"}
                  handleDecideNextMove={handleDecideNextMoveWrapper} />
              </Col>
            </Row>
            <Row className="justify-content-sm-center">
              <Col>
                <MoveButton
                  disabled={!hg.isMoveLegal("shit", myState)}
                  move="shit"
                  highlight={decidedMove === "shit"}
                  handleDecideNextMove={handleDecideNextMoveWrapper} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={3} lg={2}>
          <HamburgerArea
            name={opponentState.name}
            number={opponentState.hamburgerNumber}
            background="rgb(255, 1, 7, 0.1)" />
        </Col>
      </Row>
      <Row>
        {
          moveHistory.slice(0, 5).map(record =>
            <p key={record.roundNumber}>
              第{record.roundNumber}回合：
              {kMovePresentation.get(record.myMove)![0]}&nbsp;
              对&nbsp;
              {kMovePresentation.get(record.opponentMove)![0]}
            </p>)
        }
      </Row>
    </Container>
  </div>;
};

export {
  GamePanel,
};