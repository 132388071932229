function dateTimeStringToChineseDate(datetime: string): string {
    try {
        let date = Date.parse(datetime);
        return (new Intl.DateTimeFormat("zh-CN")).format(date);
    } catch (err) {
        return datetime;
    }
};

function isNullOrUndefined(x: any) : x is null | undefined {
    return x == null;
}

export {
    dateTimeStringToChineseDate,
    isNullOrUndefined,
};